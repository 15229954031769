@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/367117_2_0.eot');
  src: url('../fonts/367117_2_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/367117_2_0.woff2') format('woff2'),
    url('../fonts/367117_2_0.woff') format('woff'),
    url('../fonts/367117_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/367117_1_0.eot');
  src: url('../fonts/367117_1_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/367117_1_0.woff2') format('woff2'),
    url('../fonts/367117_1_0.woff') format('woff'),
    url('../fonts/367117_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/367117_3_0.eot');
  src: url('../fonts/367117_3_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/367117_3_0.woff2') format('woff2'),
    url('../fonts/367117_3_0.woff') format('woff'),
    url('../fonts/367117_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/367117_0_0.eot');
  src: url('../fonts/367117_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/367117_0_0.woff2') format('woff2'),
    url('../fonts/367117_0_0.woff') format('woff'),
    url('../fonts/367117_0_0.ttf') format('truetype');
}

/* COMPLIMENT THEME FONTS */
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@700&display=swap');

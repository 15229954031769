@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Bitter:wght@700&display=swap);
@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 100;
  src: url(/static/media/367117_2_0.1fc18af8.eot);
  src: url(/static/media/367117_2_0.1fc18af8.eot?#iefix) format('embedded-opentype'),
    url(/static/media/367117_2_0.7751ea90.woff2) format('woff2'),
    url(/static/media/367117_2_0.75afb1e4.woff) format('woff'),
    url(/static/media/367117_2_0.e087a7f0.ttf) format('truetype');
}

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/367117_1_0.57ec77c6.eot);
  src: url(/static/media/367117_1_0.57ec77c6.eot?#iefix) format('embedded-opentype'),
    url(/static/media/367117_1_0.ceec9130.woff2) format('woff2'),
    url(/static/media/367117_1_0.43d96351.woff) format('woff'),
    url(/static/media/367117_1_0.cb893222.ttf) format('truetype');
}

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/367117_3_0.4b1c284e.eot);
  src: url(/static/media/367117_3_0.4b1c284e.eot?#iefix) format('embedded-opentype'),
    url(/static/media/367117_3_0.5b1326b6.woff2) format('woff2'),
    url(/static/media/367117_3_0.56f8fa75.woff) format('woff'),
    url(/static/media/367117_3_0.4571cdb2.ttf) format('truetype');
}

@font-face {
  font-family: 'Avant Garde Gothic ITC';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/367117_0_0.23720414.eot);
  src: url(/static/media/367117_0_0.23720414.eot?#iefix) format('embedded-opentype'),
    url(/static/media/367117_0_0.ce915ff8.woff2) format('woff2'),
    url(/static/media/367117_0_0.9f37f065.woff) format('woff'),
    url(/static/media/367117_0_0.5c89d3c3.ttf) format('truetype');
}

/* COMPLIMENT THEME FONTS */

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;

  background: #fff;
  border: none;
  font-size: 16px;
  min-height: 55px;
  margin: 0;
  padding: 10px 10px 5px;

  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-webkit-input-placeholder {
  color: #a3a3a3;
}

.react-tags__search input:-ms-input-placeholder {
  color: #a3a3a3;
}

.react-tags__search input::-ms-input-placeholder {
  color: #a3a3a3;
}

.react-tags__search input::placeholder {
  color: #a3a3a3;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

